import type React from 'react';
import { useContext } from 'react';
import { useCombinedProduct } from '../../../Hooks/UseCombinedProduct';
import ColorSelectBox from './Attribute/ColorSelectBox';
import ButtonGroup from './Attribute/ButtonGroup';
import Image from './Attribute/Image';
import SelectList from './Attribute/SelectList';
import { useTypedSelector } from '../../../Store/connectors';
import List from './Attribute/List';
import { WebshopContext } from '../../../../general/WebshopContext/WebshopContextProvider';
import { combinedArticleTest } from '../../../../../js/optimizely/fx/flags';

const CombinedArticle: React.FC = () => {
    const combinedArticle = useTypedSelector((state) => state.combinedArticle);
    const showUnpublished = useTypedSelector(({ meta }) => meta.showUnpublished);
    const variantId = useTypedSelector(({ product }) => product.variantId);
    const selectedDesignId = useTypedSelector(({ product }) => product.selectedDesignId);
    const { experimentVariation } = useContext(WebshopContext);
    const isVariationB = experimentVariation === combinedArticleTest.variationB;

    const { updateCombinedProduct } = useCombinedProduct();

    if (combinedArticle === null) {
        return null;
    }

    const lastAttributeId = combinedArticle.data[combinedArticle.data.length - 1]?.id;

    const onChange = (attributeId: number, valueId: number) => {
        const selections = { ...combinedArticle.selectedValues };
        selections[attributeId] = valueId;

        const selectedDesign = selectedDesignId || -1;
        const data = {
            abTestVersionAjax: experimentVariation,
            attributes: selections,
            combinedArticleId: combinedArticle.id,
            selectedDesignId: selectedDesign,
            showUnpublished,
            variantId,
        };
        updateCombinedProduct(data);
    };

    return (
        <div className="combinedArticleData" data-show-unpublished={showUnpublished}>
            {combinedArticle.data.map((attribute) => (
                <div key={attribute.id}
                    className={attribute.values.length > 1 ? 'combinedArticleAttributeRow' : 'combinedArticleAttributeRow hidden'}>
                    <div className="attributeTitle">{attribute.name}</div>
                    <div className="attributeValues">
                        {attribute.type === 'colorselectbox' && (
                            <ColorSelectBox
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                onChange={onChange}
                            />
                        )}
                        {attribute.type === 'buttongroup' && (
                            <ButtonGroup
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                valuePrices={combinedArticle.valuePrices} onChange={onChange}
                            />
                        )}
                        {attribute.type === 'image' && (
                            <Image
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                valuePrices={combinedArticle.valuePrices}
                                onChange={onChange}
                            />
                        )}
                        {!isVariationB && attribute.type === 'selectlist' && (
                            <SelectList
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                valuePrices={combinedArticle.valuePrices}
                                onChange={onChange}
                            />
                        )}
                        {isVariationB && attribute.type === 'selectlist' && lastAttributeId !== attribute.id && (
                            <SelectList
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                valuePrices={combinedArticle.valuePrices}
                                onChange={onChange}
                            />
                        )}
                        {isVariationB && attribute.type === 'selectlist' && lastAttributeId === attribute.id && (
                            <List
                                attribute={attribute}
                                selectedValues={combinedArticle.selectedValues}
                                disabledValues={combinedArticle.disabledValues}
                                valuePrices={combinedArticle.valuePrices}
                                onChange={onChange}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CombinedArticle;
